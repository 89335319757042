require("./bootstrap");

// core version + navigation, pagination modules:
import Swiper from "swiper";
import {
    Autoplay,
    Navigation,
    Pagination,
    EffectFade,
    Mousewheel,
    Scrollbar
} from "swiper/modules";
// import Swiper and modules styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import "swiper/css/scrollbar";

// Initialization for ES Users
import {
    Input,
    Select,
    Tab,
    Dropdown,
    Modal,
    Ripple,
    Collapse,
    PerfectScrollbar,
    initTE,
} from "tw-elements";

window.onload = (event) => {
    console.log("page is fully loaded");

    initTE({
        Input,
        Select,
        Tab,
        Dropdown,
        Modal,
        Ripple,
        Collapse,
        PerfectScrollbar,
    });

    // Get the button
    const backtop = document.getElementById("btn-back-to-top");

    // When the user scrolls down 20px from the top of the document, show the button

    const scrollFunction = () => {
        if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
        ) {
            backtop.classList.remove("hidden");
        } else {
            backtop.classList.add("hidden");
        }
    };
    const backToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // When the user clicks on the button, scroll to the top of the document
    backtop.addEventListener("click", backToTop);

    window.addEventListener("scroll", scrollFunction);

    // init Swiper:
    const desktop_banner = new Swiper(".home-banner", {
        // configure Swiper to use modules
        modules: [EffectFade, Autoplay, Pagination],
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        // effect: "fade",
        // fadeEffect: {
        //     crossFade: true,
        // },
        speed: 500,
        loop: true,
        slidesPerView: 1,

        // If we need pagination
        pagination: {
            el: ".home-banner .swiper-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    const mobile_banner = new Swiper(".home-banner-mobile", {
        // configure Swiper to use modules
        modules: [EffectFade, Autoplay, Pagination],
        autoplay: {
            delay: 3000,
            disableOnInteraction: false,
        },
        // effect: "fade",
        // fadeEffect: {
        //     crossFade: true,
        // },
        speed: 2000,
        loop: true,
        slidesPerView: 1,

        // If we need pagination
        pagination: {
            el: ".home-banner-mobile .swiper-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    const how_to_list = new Swiper(".how-to-list", {
        modules: [Mousewheel, Scrollbar],
        loop: false,
        spaceBetween: 50,
        slidesPerView: "auto",
        freeMode: true,
        mousewheel: {
            releaseOnEdges: false,
        },
        scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
        },
    });

    const ourprize = new Swiper(".ourprize", {
        // configure Swiper to use modules
        modules: [EffectFade, Autoplay, Pagination],
        // autoplay: {
        //     delay: 1000,
        //     disableOnInteraction: false
        // },
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        speed: 1000,
        loop: true,
        slidesPerView: 1,
        // If we need pagination
        pagination: {
            el: ".ourprize .swiper-pagination",
            type: "bullets",
            clickable: true,
        },
    });

    const timeline_set = new Swiper(".timeline_set", {
        // configure Swiper to use modules
        modules: [EffectFade, Navigation],
        slidesPerView: 1,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        speed: 500,
        navigation: {
            nextEl: ".timeline_set .button-next",
            prevEl: ".timeline_set .button-prev",
        },
    });

    const potential = new Swiper(".potential_set", {
        // configure Swiper to use modules
        modules: [EffectFade, Autoplay, Pagination],
        speed: 2000,
        autoplay: {
            delay: 1000,
            disableOnInteraction: true,
        },
        slidesPerView: 1.5,
        initialSlide: 1,
        spaceBetween: 20,
        centeredSlides: true,
        pagination: {
            el: ".potential_set .swiper-pagination",
            clickable: true,
        },
        breakpoints: {
            1024: {
                slidesPerView: 3,
                spaceBetween: 30,
            },
        },
    });

    const achievement = new Swiper(".achievement", {
        modules: [Navigation, Mousewheel],
        mousewheel: {
            releaseOnEdges: false,
            sensitivity: 1,
        },
        slidesPerView: 2.1,
        slidesPerGroup: 2,
        slidesOffsetAfter: 10,
        freeMode: true,
        spaceBetween: 16,
        // speed: 500,
        navigation: {
            nextEl: ".achievement-wrapper .button-next",
            prevEl: ".achievement-wrapper .button-prev",
        },
        breakpoints: {
            768: {
                slidesPerView: 3.1,
                slidesPerGroup: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 4.1,
                slidesPerGroup: 4,
                spaceBetween: 30,
            },
        },
    });

    let hash = window.location.hash;
    let check_hash = hash.startsWith("#tabs");

    if (check_hash) {
        const triggerEl = document.querySelector(
            '#download_tab a[href="' + hash + '"]'
        );
        if (triggerEl !== null) {
            const tabTrigger = new Tab(triggerEl);
            tabTrigger.show(); // Select tab by name
            // download_tab
            triggerEl.scrollIntoView({
                behavior: "smooth",
            });
        }
    }
};
